
          @use '@/assets/scss/colors' as color;
          @use '@/assets/scss/typography' as typography;
          @use '@/assets/scss/constants' as constant;
          @use '@/assets/scss/mixins' as mixin;
          @use '@/assets/scss/functions' as function;
          @use '@/assets/scss/cssVar' as cssVar;
        




































































































































































































































































.Scene {
  position: relative;
  width: 100%;
  height: 100%;

  &-fps {
    padding: 10px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &-canvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    outline: none;
    display: block;

    // TODO: Double-Check on iOS
    position: fixed;
    top: 0;
  }

  &-actions {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
  }

  &-overlay {
    transition: opacity 0.25s linear;
    position: relative;
    z-index: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}
