
          @use '@/assets/scss/colors' as color;
          @use '@/assets/scss/typography' as typography;
          @use '@/assets/scss/constants' as constant;
          @use '@/assets/scss/mixins' as mixin;
          @use '@/assets/scss/functions' as function;
          @use '@/assets/scss/cssVar' as cssVar;
        









































.DefaultButton {
  border: 2px solid rgba(80, 80, 80, 0.5);
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.1s linear;
  transition-property: border-color, text, opacity;
  border-radius: 5px;

  &:disabled {
    color: inherit;
    pointer-events: none;
    opacity: 0.7;
  }

  &:hover {
    border-color: rgba(80, 80, 80, 1);
  }

  &--light {
    border: 2px solid rgba(#fff, 0.5);
    color: #fff;

    &:hover {
      border-color: rgba(#fff, 1);
    }
  }

  &--small {
    padding: 1px 10px;
  }
}
