
          @use '@/assets/scss/colors' as color;
          @use '@/assets/scss/typography' as typography;
          @use '@/assets/scss/constants' as constant;
          @use '@/assets/scss/mixins' as mixin;
          @use '@/assets/scss/functions' as function;
          @use '@/assets/scss/cssVar' as cssVar;
        






















































































































.Overlay {
  $block: &;

  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s linear;
  opacity: 1;
  background-color: rgba(#000, 0.5);
  backdrop-filter: blur(10px);

  @include mixin.interpolate(
    padding,
    constant.$contentMinWidth,
    constant.$contentMaxWidth,
    20px,
    50px
  );

  &-content {
    @include mixin.interpolate(
      padding,
      constant.$contentMinWidth,
      constant.$contentMaxWidth,
      30px,
      50px
    );

    width: 100%;
    max-width: 600px;
    overflow: auto;
    color: rgb(70, 70, 70);
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 3.6px 3.9px rgba(0, 0, 0, 0.04),
      0 12.1px 13px rgba(0, 0, 0, 0.06), 0 54px 58px rgba(0, 0, 0, 0.1);
  }

  &-headline {
    font-family: 'Portabella', sans-serif;
    font-weight: normal;
    line-height: 0.8;
    margin-bottom: 0.3em;

    @include mixin.interpolate(
      font-size,
      constant.$contentMinWidth,
      constant.$contentMaxWidth,
      50px,
      90px
    );
  }

  &-text {
    //
  }

  &-button {
    margin-top: 1em;
  }

  &-help {
    margin-top: 1em;
    font-size: 16px;
    opacity: 0.7;
  }

  &-makingOfLink {
    text-decoration: underline;
    margin-top: 0.5em;
    display: inline-block;
  }
}
