// Linear Gradients, Source: https://www.npmjs.com/package/postcss-easing-gradients
// background: linear-gradient(90deg, rgba(color.$grey-500, 1), ease, rgba(color.$grey-500, 0));

// Interpolate Mixing
// @include mixin.interpolate(font-size padding, 500px, 1200px, 26px, 44px);

:root {
  --fontStack-default: #{constant.$fontStack-default};
  --fontSize-default: 16px;
  --fontSize-large: 20px;
  --lineHeight-default: 1.4;

  @include mixin.interpolate(
    --space-smallest,
    constant.$contentMinWidth,
    constant.$contentMaxWidth,
    10px,
    20px
  );

  // Media Queries für responsive Anpassung hier
}

html,
body {
  height: 100%;
}

html {
  // Smooth scrolling to #links
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  min-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-text-stroke: 0.2px rgba(0,0,0, 0.5) // wenn Text in Webkit zu dünn
  // text-shadow: #fff 0px 1px 1px; // Wenn Text in allen Browsern zu dünn

  // Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  // These are technically the same, but use both
  overflow-wrap: break-word;
  word-wrap: break-word;

  // Prevent iOS to enlarge font-size
  -webkit-text-size-adjust: none;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  @include mixin.media('<=600px') {
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
  }

  font-family: var(--fontStack-default);
  font-size: var(--fontSize-default);
  line-height: var(--lineHeight-default);
}
