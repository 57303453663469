.defaultText {
  font-family: var(--fontStack--sans);
  font-size: var(--fontSize-default);

  & p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    line-height: 1.2;
  }

  & h2 {
    // @include ms-respond(font-size, 5); // TODO
    // @include ms-respond(margin-bottom, 2);

    color: color.$key;
  }

  & h3 {
    // @include ms-respond(font-size, 3); // TODO
    // @include ms-respond(margin-bottom, 1);
  }

  & h4 {
    // @include ms-respond(font-size, 2); // TODO
    // @include ms-respond(margin-bottom, 0);
  }

  & a {
    color: color.$key;

    &:hover {
      // opacity: 0.75;
    }
  }
}

.defaultText.-lists {
  ul {
    margin-bottom: 1em;

    & ul {
      margin-left: 5px;
      font-weight: 300;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & li {
      position: relative;
      padding-left: 10px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: currentColor;
        position: absolute;
        user-select: none;
        top: 10px;
        left: 0;
      }
    }
  }
}
