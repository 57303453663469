
          @use '@/assets/scss/colors' as color;
          @use '@/assets/scss/typography' as typography;
          @use '@/assets/scss/constants' as constant;
          @use '@/assets/scss/mixins' as mixin;
          @use '@/assets/scss/functions' as function;
          @use '@/assets/scss/cssVar' as cssVar;
        
























// @import '../node_modules/reset-css/reset.css';
@import '@/assets/scss/reset.scss';
// @import './assets/scss/reset.scss';
@import './assets/scss/default.scss';
@import './assets/scss/defaultText.scss';

@include mixin.font-face('Portabella', '/fonts/portabella');
@include mixin.font-face('Kreon', '/fonts/kreon-regular');

.App {
  width: 100%;
  height: 100%;
  font-family: Kreon, sans-serif;
  line-height: 1.4;

  @include mixin.interpolate(
    font-size,
    constant.$contentMinWidth,
    constant.$contentMaxWidth,
    18px,
    20px
  );
}
